import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/layout.tsx";
import { price } from "../../utils/price";
import { rooms } from "../../data/rooms";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Content = makeShortcode("Content");
const ContentColumns = makeShortcode("ContentColumns");
const Card = makeShortcode("Card");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Content mdxType="Content">
      <p>{`Pre našich hostí máme k`}{` `}{`dispozícii 14 izieb a`}{` `}{`apartmánov. Každá má vlastný balkón alebo lodžiu s`}{` `}{`očarujúcim výhľadom na
okolitú prírodu. V`}{` `}{`izbách dominujú prírodné materiály, hlavne drevo a`}{` `}{`kameň, vďaka ktorým sú naplnené fantastickou
pohodou a`}{` `}{`príjemnou horskou atmosférou.`}</p>
    </Content>
    <h2>{`Izby`}</h2>
    <ContentColumns mdxType="ContentColumns">
    {Object.keys(rooms).map(key => {
        const room = rooms[key];
        return <Card key={key} variant="vertical" image={room.image} title={room.title} mdxType="Card">
        		{price(room.price)} / noc
        	</Card>;
      })}
    </ContentColumns>
    <Content mdxType="Content">
      <h2>{`V každej izbe sa nachádza`}</h2>
      <ul>
        <li parentName="ul">{`veľká manželská posteľ`}</li>
        <li parentName="ul">{`kúpeľňa so sprchovacím kútom a WC`}</li>
        <li parentName="ul">{`šatníková skriňa`}</li>
        <li parentName="ul">{`balkón alebo lodžia`}</li>
        <li parentName="ul">{`TV`}</li>
        <li parentName="ul">{`uteráky a kozmetika v kúpeľni`}</li>
      </ul>
      <h2>{`Poplatky`}</h2>
      <ul>
        <li parentName="ul">{`Minimálna dĺžka ubytovania sú 2 noci, príplatok pri obsadenosti na 1 noc je `}<strong parentName="li">{`10 € / izba`}</strong></li>
        <li parentName="ul">{`1 osoba v dvojlôžkovej izbe Štandard `}<strong parentName="li">{`50 € / noc`}</strong></li>
        <li parentName="ul">{`Rekreačný poplatok `}<strong parentName="li">{`0,50 € / noc / osoba`}</strong>{` (nie je v cene pobytu)`}</li>
        <li parentName="ul">{`Deti do 3 rokov bez nároku na lôžko `}<strong parentName="li">{`zadarmo`}</strong></li>
        <li parentName="ul">{`Detská postieľka `}<strong parentName="li">{`20 € / pobyt`}</strong></li>
      </ul>
      <h2>{`Cena polpenzie - raňajky a večere`}</h2>
      <ul>
        <li parentName="ul">{`Dospelá osoba `}<strong parentName="li">{`25 € / deň`}</strong></li>
        <li parentName="ul">{`Deti od 7 do 12 rokov `}<strong parentName="li">{`17 € / deň`}</strong></li>
        <li parentName="ul">{`Deti od 3 do 6 rokov `}<strong parentName="li">{`12 € / deň`}</strong></li>
        <li parentName="ul">{`Deti do 3 rokov `}<strong parentName="li">{`zadarmo`}</strong></li>
      </ul>
      <p>{`*`}{` Všetky uvedené ceny sú vrátane DPH.`}<br />{`
`}{`*`}{` Ceny v termíne od 26. 12., vrátane Silvestrovských balíčkov na vyžiadanie.`}</p>
      <h2>{`Hotelové služby`}</h2>
      <ul>
        <li parentName="ul">{`požičovňa lyžiarskych potrieb priamo v hoteli`}</li>
        <li parentName="ul">{`uschovanie lyžiarskej výstroje priamo v hoteli - vlastná lyžiareň`}</li>
        <li parentName="ul">{`parkovanie pri hoteli`}</li>
        <li parentName="ul">{`spoločenské hry pre rodiny dostupné na recepcii`}</li>
        <li parentName="ul">{`detský kútik`}</li>
      </ul>
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      